import request from '@/libs/axios'

// API USERS
export function getUsers(data) {
  return request({
    url: '/api/users',
    method: 'get',
    params: data,
  })
}
export function addUser(data) {
  return request({
    url: '/api/users',
    method: 'post',
    data,
  })
}
export function editUser(data) {
  return request({
    url: `/api/users/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function getUser(data) {
  return request({
    url: `/api/users/${data.id}`,
    method: 'get',
  })
}

export function deleteUser(data) {
  return request({
    url: `/api/users/${data.id}`,
    method: 'delete',
  })
}
export function getRole(data) {
  return request({
    url: '/api/roles',
    method: 'get',
    params: data,
  })
}

export function fetchUserStatistics(params) {
  return request({
    url: '/api/documents/get-user-statistics',
    method: 'get',
    params,
  })
}

export function getUserStatistics() {
  return request({
    url: '/api/get-user-statistics',
    method: 'get',
  })
}

export function getUserNotifications() {
  return request({
    url: '/api/get-user-notifications',
    method: 'get',
  })
}
