var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"news-form"},[_c('b-overlay',{attrs:{"show":_vm.loading,"no-wrap":"","spinner-variant":"primary"}}),_c('validation-observer',{ref:"observer",staticClass:"w-100"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Username'),"label-for":"name-input","invalid-feedback":"Name is required"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Username'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","required":"","size":"sm"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"name-input","invalid-feedback":"Name is required"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","required":"","size":"sm"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Firstname'),"label-for":"name-input","invalid-feedback":"Name is required"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Firstname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","required":""},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Lastname'),"label-for":"name-input","invalid-feedback":"Name is required"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Lastname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","required":""},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management_type'),"label-for":"name-input","invalid-feedback":"ManagementType is required"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.management_type,"text-field":"name","value-field":"id"},on:{"input":_vm.getManagementCategory},model:{value:(_vm.form.management_type_id),callback:function ($$v) {_vm.$set(_vm.form, "management_type_id", $$v)},expression:"form.management_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Management'),"label-for":"name-input","invalid-feedback":"ManagementType is required"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Management'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.management_category,"text-field":"name","value-field":"id"},model:{value:(_vm.form.management_category_id),callback:function ($$v) {_vm.$set(_vm.form, "management_category_id", $$v)},expression:"form.management_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Role'),"label-for":"name-input","invalid-feedback":"ManagementType is required"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Role'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.roles,"text-field":"name","value-field":"id"},model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.id)?_c('b-form-group',{attrs:{"label":_vm.$t('Password'),"label-for":"name-input","invalid-feedback":"Name is required"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name-input","type":"password","required":"","size":"sm"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2485211664)})],1):_c('b-form-group',{attrs:{"label":_vm.$t('Password'),"label-for":"name-input","invalid-feedback":"Name is required"}},[_c('b-form-input',{attrs:{"id":"name-input","type":"password","required":"","size":"sm"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1)],1)],1),_c('div',{staticClass:"text-right mt-3"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('closeModal')}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.saveSubmit}},[_vm._v(" "+_vm._s(_vm.$t('Add_User'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }