<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="2">
          <div class="input-group">
            <b-input
                id="example-search-input"
                class="form-control w-100"
                type="search"
                :placeholder="$t('Search')"
                v-model="filters.search"
                @input="getSearchUser"
            />
          </div>
        </b-col>
        <b-col md="2">
          <v-select :options="roles"
                    label="display_name"
                    v-model="role"
                    :placeholder="$t('Role')"
                    @input="getByRole" @option:deselected="getByCategory"></v-select>
        </b-col>
        <b-col md="2">
          <v-select :options="management_type"
                    label="name"
                    v-model="type"
                    :placeholder="$t('Management_type')"
                    @input="getManagementCategory"></v-select>
        </b-col>
        <b-col md="4">
          <v-select :options="management_category"
                    label="name"
                    v-model="mcategory"
                    :placeholder="$t('Management')"
                    @input="getByCategory"></v-select>
        </b-col>
        <b-col md="2">
          <div class="w-100 d-flex justify-content-end">
            <b-button variant="warning" class="mr-1" @click="clearFilter">
              <b-icon icon="arrow-repeat"></b-icon>
            </b-button>
            <b-button variant="primary" @click="handleAdd" >{{$t('Add')}}</b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div class="w-100 mt-2">
      <b-overlay
          id="overlay-background"
          :show="showTableOverlay"
          rounded="sm"
      >
        <b-card>
          <b-table
              striped
              hover
              :items="datas"
              :fields="fields"
              responsive=""
          >
            <template #cell(id)="data">
              <span class="text-md text-primary"> {{ (data.index +1) + (pagination.pageSize)*(pagination.page-1) }}</span>
            </template>
            <template #cell(action)="data">
              <div class="d-flex">
                <b-button
                    size="sm"
                    variant="warning"
                    @click="handleEdit(data.item.id)"
                >
                  <b-icon icon="pencil" />
                </b-button>
                <b-button
                    class="ml-1"
                    size="sm"
                    variant="danger"
                    @click="handleDelete(data.item.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-overlay>

    </div>
    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        v-model="modalVisible"
        :title="modalTitle"
        hide-footer
        size="xl"
    >
      <UserForm :roles="roles" :id="id" @saved="fetchList" @closeModal="closeModal" />
    </b-modal>
    <div class="overflow-auto text-center">
      <b-pagination
          v-model="pagination.page"
          align="center"
          :total-rows="total"
          :per-page="pagination.pageSize"
          first-number
          size="lg"
          class="pt-2"
          @input="fetchList"
      />
    </div>
  </div>
</template>

<script>
import { deleteUser, getRole, getUsers } from '@/api/users'
import { listTemplate } from '@/utils/mixins/listTemplate'
// eslint-disable-next-line import/extensions
import UserForm from '@/views/loginpages/moderator/users/UserForm'
import { getManagement, getManagementTypes } from '@/api/directories'
import vSelect from 'vue-select'

const actions = {
  get: getUsers,
  delete: deleteUser,
}

export default {
  name: 'Index',
  // eslint-disable-next-line no-undef
  components: { UserForm, vSelect },
  mixins: [
    listTemplate,
  ],
  data() {
    return {
      actions,
      rows: 0,
      currentPage: 1,
      perPage: 0,
      users: [],
      modalVisible: false,
      activeDocs: null,
      management_type: [],
      management_category: [],
      mcategory: null,
      roles: [],
      type: null,
      role: null,
      filters: {
        search: '',
        category: null,
        role_id: null,
      },
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'name',
          label: this.$t('Name'),
        },
        {
          key: 'email',
          label: this.$t('Email'),
        },

        {
          key: 'firstname',
          label: this.$t('First_Name'),
        },
        {
          key: 'lastname',
          label: this.$t('Last_Name'),
        },
        {
          key: 'management_category.name',
          label: this.$t('Management ID'),
        },
        {
          key: 'management_type.name',
          label: this.$t('Type_id'),
        },
        {
          key: 'role.display_name',
          label: this.$t('Role_id'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
      timeOutVar: null,
      showTableOverlay: false,
    }
  },
  mounted() {
    getManagementTypes().then(res => {
      this.management_type = res.data.data
    });
    this.fetchRoles()
  },
  methods: {
    getManagementCategory() {
      getManagement({ type_id: this.type.id, all: true }).then(res => {
        this.management_category = res.data.data.data
      })
    },
    fetchRoles() {
      getRole().then(res => {
        this.roles = res.data.data
      })
    },
    getByCategory() {
      this.filters.category = this.mcategory.id;
      this.pagination.page = 1
      this.fetchList()
    },
    getSearchUser() {
      clearTimeout(this.timeOutVar)
      this.pagination.page = 1
      this.timeOutVar = setTimeout(() => {
        this.fetchList()
      }, 1000)
    },
    clearFilter() {
      this.filters.search = '';
      this.filters.category = null;
      this.fetchList()
    },
    getByRole() {
      this.filters.role_id = this.role.id;
      this.fetchList()
    },
    closeModal() {
      this.modalVisible = false;
    }
  },
}
</script>

<style scoped>

</style>
