<template>
  <section class="news-form">
    <b-overlay :show="loading" no-wrap spinner-variant="primary"></b-overlay>
    <validation-observer ref="observer" class="w-100">
    <b-row>
      <b-col md="6">
          <b-form-group
              :label="$t('Username')"
              label-for="name-input"
              invalid-feedback="Name is required"
          >
            <validation-provider
                #default="{ errors }"
                :name="$t('Username')"
                rules="required"
            >
            <b-form-input
                id="name-input"
                v-model="form.name"
                required
                size="sm"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
              :label="$t('Email')"
              label-for="name-input"
              invalid-feedback="Name is required"
          >
            <validation-provider
                #default="{ errors }"
                :name="$t('Email')"
                rules="required"
            >
            <b-form-input
                id="name-input"
                v-model="form.email"
                required
                size="sm"
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
              :label="$t('Firstname')"
              label-for="name-input"
              invalid-feedback="Name is required"
          >
            <validation-provider
                #default="{ errors }"
                :name="$t('Firstname')"
                rules="required"
            >
            <b-form-input
                id="name-input"
                v-model="form.firstname"
                required
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
              :label="$t('Lastname')"
              label-for="name-input"
              invalid-feedback="Name is required"
          >
            <validation-provider
                #default="{ errors }"
                :name="$t('Lastname')"
                rules="required"
            >
            <b-form-input
                id="name-input"
                v-model="form.lastname"
                required
            />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
            :label="$t('Management_type')"
            label-for="name-input"
            invalid-feedback="ManagementType is required">
          <validation-provider
              #default="{ errors }"
              :name="$t('Management_type')"
              rules="required"
          >
            <b-form-select
                v-model="form.management_type_id"
                :options="management_type"
                text-field="name"
                value-field="id"
                class=""
                @input="getManagementCategory"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
         <b-form-group
             :label="$t('Management')"
             label-for="name-input"
             invalid-feedback="ManagementType is required">
           <validation-provider
               #default="{ errors }"
               :name="$t('Management')"
               rules="required"
           >
             <b-form-select
                 v-model="form.management_category_id"
                 :options="management_category"
                 text-field="name"
                 value-field="id"
                 class=""
             />
             <small class="text-danger">{{ errors[0] }}</small>
           </validation-provider>
         </b-form-group>
           <b-form-group
               :label="$t('Role')"
               label-for="name-input"
               invalid-feedback="ManagementType is required">
             <validation-provider
                 #default="{ errors }"
                 :name="$t('Role')"
                 rules="required"
             >
               <b-form-select
                   v-model="form.role_id"
                   :options="roles"
                   text-field="name"
                   value-field="id"
                   class=""
               />
               <small class="text-danger">{{ errors[0] }}</small>
             </validation-provider>
           </b-form-group>
        <b-form-group
            v-if="!id"
            :label="$t('Password')"
            label-for="name-input"
            invalid-feedback="Name is required"
        >
          <validation-provider
              #default="{ errors }"
              :name="$t('Password')"
              rules="required"
          >
            <b-form-input
                id="name-input"
                v-model="form.password"
                type="password"
                required
                size="sm"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
            v-else
            :label="$t('Password')"
            label-for="name-input"
            invalid-feedback="Name is required"
        >
            <b-form-input
                id="name-input"
                v-model="form.password"
                type="password"
                required
                size="sm"
            />
        </b-form-group>
      </b-col>
    </b-row>
    </validation-observer>
    <div class="text-right mt-3">
      <b-button
          variant="danger"
          class="mr-2"
          @click="$emit('closeModal')"
      >
        {{ $t('Cancel') }}
      </b-button>
      <b-button
          variant="success"
          @click="saveSubmit"
      >
        {{ $t('Add_User') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  addUser, editUser, getUser, getRole,
} from '@/api/users'
import { getManagementTypes, getManagement } from '@/api/directories'
import { formTemplate } from '@/utils/mixins/formTemplate'
import vSelect from 'vue-select'

function initForm(data) {
  const get = (key, value = null) => _.get(data, key, value)

  return {
    id: get('id', null),
    name: get('name', null),
    firstname: get('firstname', null),
    lastname: get('lastname', null),
    management_category_id: get('management_category_id', null),
    email: get('email', null),
    password: get('password', null),
    management_type_id: get('management_type_id', null),
    role_id: get('role_id', null),
  }
}

const actions = {
  add: addUser,
  update: editUser,
  show: getUser,
}

export default {
  name: 'UserForm',
  props: {
    id: {
      type: Number
    },
    roles: {
      type: Array,
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  mixins: [
    formTemplate,
  ],
  data() {
    return {
      actions,
      required,
      management_type: [],
      management_category: [],
      form: initForm(),
    }
  },
  created() {
    this.fetchManagementTypes()
  },
  methods: {
    fetchManagementTypes() {
      getManagementTypes().then(res => {
        this.management_type = res.data.data
      })
    },
    getManagementCategory() {
      getManagement({ type_id: this.form.management_type_id, all: true }).then(res => {
        if (res.data.data.data) {
          this.management_category = res.data.data.data
        }
        else {
          this.management_category = res.data.data
        }
      })
    },
    reformatData() {
      const form = this.prepareFormData(this.form)
      return form
    },
    setForm(data) {
      this.form = initForm(data)
    },
  },
}
</script>
