import request from '@/libs/axios'

// API MANAGEMENT
export function getManagement(data) {
  return request({
    url: '/api/management',
    method: 'get',
    params: data,
  })
}
export function addManagement(data) {
  return request({
    url: '/api/management',
    method: 'post',
    data,
  })
}
export function editManagement(data) {
  return request({
    url: `/api/management/${data.id}`,
    method: 'put',
    data,
  })
}
export function deleteManagement(data) {
  return request({
    url: `/api/management/${data.id}`,
    method: 'delete',
  })
}
// API YEARS
export function getYears(params) {
  return request({
    url: '/api/documents/years',
    method: 'get',
    params,
  })
}
export function addYear(data) {
  return request({
    url: '/api/documents/years',
    method: 'post',
    data,
  })
}
export function editYear(data) {
  return request({
    url: `/api/documents/years/${data.id}`,
    method: 'put',
    data,
  })
}

export function deleteYear(data) {
  return request({
    url: `/api/documents/years/${data.id}`,
    method: 'delete',
  })
}
// API MANAGEMENT TYPES
export function getManagementTypes(data) {
  return request({
    url: '/api/management-types',
    method: 'get',
    params: data,
  })
}
export function addManagementTypes(data) {
  return request({
    url: '/api/management-types',
    method: 'post',
    data,
  })
}
export function editManagementTypes(data) {
  return request({
    url: `/api/management-types/${data.id}`,
    method: 'put',
    data,
  })
}
export function deleteManagementTypes(data) {
  return request({
    url: `/api/management-types/${data.id}`,
    method: 'delete',
  })
}

export function showManagementType(id) {
  return request({
    url: `/api/management-types/${id}`,
    method: 'get',
  })
}

export function getStatuses() {
  return request({
    url: '/api/documents/statuses',
    method: 'get',
  });
}

export function generateParagraphExcel(params) {
  return request({
    url: '/api/paragraph-excel',
    method: 'get',
    params,
    responseType: 'blob',
  })
}

export function generateParagraphHistoryExcel(params) {
  return request({
    url: '/api/paragraph-histories-excel',
    method: 'get',
    params,
    responseType: 'blob',
  })
}


